<template>
    <p>Access Limitted</p>
  </template>
  
  <script>
  export default {
    name: 'ErrorPage',
  };
  </script>
  
  <style scoped>
  </style>