<template>
    <div class="content">
        <h1>{{ $t('message.title') }}</h1>
        <p>{{ $t('message.greeting') }}</p>
        <p>{{ $t('message.introduction') }}</p>

        <h2>{{ $t('message.benefitsTitle') }}</h2>
        <h3>1. {{ $t('message.directoryPromotion') }}</h3>
        <p>{{ $t('message.directoryDetails') }}</p>
        <h3>2. {{ $t('message.rebateProgram') }}</h3>
        <p>{{ $t('message.rebateDetails') }}</p>

        <h2>{{ $t('message.requirementsTitle') }}</h2>
        <h3>1. {{ $t('message.quickResponse') }}</h3>
        <p>{{ $t('message.responseDetails') }}</p>
        <h3>2. {{ $t('message.friendlyCommunication') }}</h3>
        <p>{{ $t('message.communicationDetails') }}</p>
        <h3>3. {{ $t('message.localCurrency') }}</h3>
        <p>{{ $t('message.currencyDetails') }}</p>

        <h2>{{ $t('message.policyTitle') }}</h2>
        <p>{{ $t('message.policyDetails') }}</p>

        <p>{{ $t('message.contactInfo') }}</p>
    </div>
</template>

<script>
export default {
    name: 'App',
};
</script>

<style scoped>
.content {
    text-align: left;
    /* Ensure all text within the content class is aligned to the left */
    margin: 20px;
    /* Optional, adds some spacing around the content */
}
</style>