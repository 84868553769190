<template>
    <div class="invitation-container" :dir="direction">
        <MessageModal :isVisible="showModal" :message="modalMessage" @close="showModal = false" />

        <div class="invitation-header">
            <h1>{{ $t('message.welcome') }}</h1>
            <p>{{ $t('message.welcomeMsg') }}</p>
        </div>
        <div class="button-container">
            <button class="btn" @click="submitForm">{{ $t('message.download') }}</button>
            <button class="btn" @click="downloadApk">{{ $t('message.download2') }}</button>
        </div>
        
    </div>
</template>

<script>
import MessageModal from '../components/MessageModal.vue';

// function isAndroidUser() {
// const userAgent = navigator.userAgent || navigator.vendor || window.opera;
// return /Android/i.test(userAgent) && !/Windows|Macintosh|Mac OS/i.test(userAgent);
// }

export default {
    computed: {
        direction() {
            // List of RTL language codes
            const rtlLanguages = ['ar', 'he', 'fa', 'ur', 'az', 'ckb', 'ps', 'ug', 'dv'];
            // Check if the current locale is in the list of RTL languages
            return rtlLanguages.includes(this.$i18n.locale) ? 'rtl' : 'ltr';
        }
    },
    components: {
        MessageModal
    },
    data() {
        return {
            email: '', // This will be bound to your email input field
            showModal: false,
            modalMessage: ''
            // ... other data properties
        };
    },
    methods: {
        submitForm() {
            window.location.href = 'https://play.google.com/store/apps/details?id=net.mfree.vx'; // Replace with your Google Play link
        },
        downloadApk() {
            window.location.href = 'https://share.mfree.net/res/amanlink_latest.apk';
        }
    }
};
</script>

<style scoped>
.invitation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: 100vh;
}

.invitation-header h1,
.invitation-header p {
    text-align: center;
    color: #333;
    /* Dark text for contrast */
}

.button-container {
    display: flex;
    justify-content: center;
    /* Aligns items on the main axis (horizontal) */
    gap: 1rem;
    /* Optional: adds space between buttons */
}

.btn {
    background-color: green;
    /* Button background color */
    color: white;
    /* Button text color */
    padding: 0.5rem 1rem;
    /* Padding inside the button */
    border: none;
    /* Removes the border */
    border-radius: 4px;
    /* Optional: rounds the corners of the button */
}
</style>