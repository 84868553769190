<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
  // You can include additional options here such as data, methods, computed properties, etc.
}
</script>

<style>
/* Global styles for your app */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;


  background-image: url('@/assets/bg_login.png');
  background-position: left top;
  background-size: cover;
  background-repeat: no-repeat;
}

html, body {
    margin: 0;
    padding: 0;
}
</style>