<template>
    <div class="main-page" :dir="direction">
        <header>
            <h1>{{ $t(`message.pay_fail`) }}</h1>
        </header>
        <footer>
            <div class="footer-links">
                <a href="https://sites.google.com/view/amanlinkprivatepolicy"><u>Privacy Policy</u></a>
                <a href="https://sites.google.com/view/aman-link-user-agreement"><u>User Agreement</u></a>
                <a href="https://t.me/amanlinkvpn"><u>Telegram</u></a>
                <a href="https://www.instagram.com/amanlinkvpn/"><u>Instagram</u></a>
                <a href="mailto:norman@mfree.net"><u>Email</u></a>
            </div>
            <p>&copy; AmanLink. All rights reserved.</p>
        </footer>
    </div>
</template>

<script>
export default {
    computed: {
        direction() {
            // List of RTL language codes
            const rtlLanguages = ['ar', 'he', 'fa', 'ur', 'az', 'ckb', 'ps', 'ug', 'dv'];
            // Check if the current locale is in the list of RTL languages
            return rtlLanguages.includes(this.$i18n.locale) ? 'rtl' : 'ltr';
        }
    }
};
</script>

<style scoped>
.main-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    text-align: center;
    /* background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%); */
    color: black;
    font-family: Arial, sans-serif;
}

header {
    display: flex;
    margin-top: 15%;
}

.logo {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
}

h1 {
    font-size: 2.5rem;
    margin: 0;
}

.subtitle {
    font-size: 1.2rem;
    margin: 10px 0 20px;
}


.app-store-button {
    display: flex;
    gap: 20px;
    /* Adjust the gap size as needed */
}

.app-store-button img {
    padding: 0;
    margin: 0;
    display: block;
    width: 150px;
    height: auto;
}

footer {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
}

.footer-links {
    margin-bottom: 10px;
}

.footer-links a {
    color: black;
    text-decoration: none;
    margin: 0 10px;
}

.footer-links a:hover {
    text-decoration: underline;
}
</style>