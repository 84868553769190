<template>
    <div class="invitation-container" :dir="direction">
        <MessageModal :isVisible="showModal" :message="modalMessage" @close="showModal = false" />

        <div class="invitation-header">
            <h1>{{ $t('message.welcome') }}</h1>
            <p>{{ $t('message.welcome2') }}</p>
        </div>
        <form class="email-form" @submit.prevent="submitForm">
            <label for="email">{{ $t('message.email') }}</label>
            <input type="email" id="email" v-model="email" required :placeholder="$t('message.hint_email')">
            <button type="submit" class="download-button">{{ $t('message.download') }}</button>
        </form>
    </div>
</template>

<script>
import MessageModal from '../components/MessageModal.vue';

// function isAndroidUser() {
    // const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // return /Android/i.test(userAgent) && !/Windows|Macintosh|Mac OS/i.test(userAgent);
// }

export default {
    computed: {
        direction() {
            // List of RTL language codes
            const rtlLanguages = ['ar', 'he', 'fa', 'ur', 'az', 'ckb', 'ps', 'ug', 'dv'];
            // Check if the current locale is in the list of RTL languages
            return rtlLanguages.includes(this.$i18n.locale) ? 'rtl' : 'ltr';
        }
    },
    components: {
        MessageModal
    },
    data() {
        return {
            email: '', // This will be bound to your email input field
            showModal: false,
            modalMessage: ''
            // ... other data properties
        };
    },
    methods: {
        submitForm() {
            // Extract the 'invitation' query parameter from the URL
            const params = new URLSearchParams(window.location.search);
            const invitation = params.get('invitation');

            // Prepare the data to be sent to the backend, including the invitation code
            const formData = {
                email: this.email,
                invitation: invitation
            };

            // Use fetch or another HTTP client to send the data to the backend
            fetch('/api/consumeInvitation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    if (data.success) {
                        // Success: Redirect the user to the appropriate store based on the device

                        // if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                        // window.location.href = 'https://apps.apple.com/app/your-app-id'; // Replace with your App Store link
                        // }
                        // Check if the user is on an Android device
                        // if (isAndroidUser()) {
                        window.location.href = 'https://play.google.com/store/apps/details?id=net.mfree.vx'; // Replace with your Google Play link
                        // } else {
                            // Provide a link to a landing page or display a message
                        this.modalMessage = this.$t(`message.msg_download_other_platform`);
                        this.showModal = true;
                        // }
                    } else if (data.message == 'invitation not found') {
                        this.modalMessage = this.$t(`message.invitation_invalid`);
                        this.showModal = true;
                    } else if (data.message == 'invitation all used') {
                        this.modalMessage = this.$t(`message.invitation_used_up`);
                        this.showModal = true;
                    } else if (data.message == 'email already registered') {
                        this.modalMessage = this.$t(`message.msg_email_exist`);
                        this.showModal = true;
                    } else {
                        throw new Error(data.message); // Use the error message from the response
                    }
                })
                .catch((error) => {
                    this.modalMessage = 'Error: ' + error.message;
                    this.showModal = true;
                });
        }
    }
};
</script>

<style scoped>
.invitation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: 100vh;
}

.invitation-header h1,
.invitation-header p {
    text-align: center;
    color: #333;
    /* Dark text for contrast */
}

.email-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    /* Adjust based on your design */
}

.email-form label {
    margin-bottom: 10px;
}

.email-form input {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.download-button {
    padding: 10px 20px;
    background-color: #4CAF50;
    /* Green background */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.download-button:hover {
    background-color: #45a049;
    /* Darker green on hover */
}
</style>