<template>
    <div class="invitation-container" :dir="direction">
        <MessageModal :isVisible="showModal" :message="modalMessage" @close="showModal = false" />

        <div class="invitation-header">
            <h1>{{ $t('message.title_account') }}</h1>
            <p>{{ $t('message.desc_account') }}</p>
        </div>
        <form class="email-form" @submit.prevent="submitForm">
            <label for="email">{{ $t('message.email') }}</label>
            <input type="email" id="email" v-model="email" required :placeholder="$t('message.hint_email')">
            <input type="password" id="password" v-model="password" required :placeholder="$t('message.hint_password')" minlength="6">
            <button type="submit" class="download-button">{{ $t('message.delete') }}</button>
        </form>
    </div>
</template>

<script>
import MessageModal from '../components/MessageModal.vue';

// function isAndroidUser() {
    // const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // return /Android/i.test(userAgent) && !/Windows|Macintosh|Mac OS/i.test(userAgent);
// }

export default {
    computed: {
        direction() {
            // List of RTL language codes
            const rtlLanguages = ['ar', 'he', 'fa', 'ur', 'az', 'ckb', 'ps', 'ug', 'dv'];
            // Check if the current locale is in the list of RTL languages
            return rtlLanguages.includes(this.$i18n.locale) ? 'rtl' : 'ltr';
        }
    },
    components: {
        MessageModal
    },
    data() {
        return {
            email: '', // This will be bound to your email input field
            showModal: false,
            modalMessage: ''
            // ... other data properties
        };
    },
    methods: {
        submitForm() {
            // Prepare the data to be sent to the backend, including the invitation code
            const formData = {
                email: this.email,
                password: this.password
            };

            // Use fetch or another HTTP client to send the data to the backend
            fetch('/api/deleteUser', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    if (data.success) {
                        this.modalMessage = this.$t(`message.msg_delete_success`);
                        this.showModal = true
                    } else if (data.message == 'user not found') {
                        this.modalMessage = this.$t(`message.msg_validate_error`);
                        this.showModal = true;
                    } else if (data.message == 'incorrect password') {
                        this.modalMessage = this.$t(`message.msg_validate_error`);
                        this.showModal = true;
                    } else {
                        throw new Error(data.message); // Use the error message from the response
                    }
                })
                .catch((error) => {
                    this.modalMessage = 'Error: ' + error.message;
                    this.showModal = true;
                });
        }
    }
};
</script>

<style scoped>
.invitation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: 100vh;
}

.invitation-header h1,
.invitation-header p {
    text-align: center;
    color: #333;
    /* Dark text for contrast */
}

.email-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    /* Adjust based on your design */
}

.email-form label {
    margin-bottom: 10px;
}

.email-form input {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.download-button {
    padding: 10px 20px;
    background-color: #4CAF50;
    /* Green background */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.download-button:hover {
    background-color: #45a049;
    /* Darker green on hover */
}
</style>