import { createRouter, createWebHistory } from 'vue-router';
import VpnInvitation from './pages/VpnInvitation.vue'
import VpnInvitation2 from './pages/VpnInvitation2.vue'
// import Home from './pages/Home.vue';
import VpnAccount from './pages/VpnAccount.vue';
import MainPage from './pages/MainPage.vue';
import BuyActivationCode from './pages/BuyActivationCode.vue';
import PaymentSuccess from './pages/PaymentSuccess.vue';
import PaymentFail from './pages/PaymentFail.vue';
import LimitPage from './pages/LimitPage.vue';
import ResellerRecruit from './pages/ResellerRecruit.vue';

const routes = [
    {
        path: '/invitation',
        name: 'Invitation',
        component: VpnInvitation2
    },
    {
        path: '/invitation2',
        name: 'Invitation2',
        component: VpnInvitation
    },
    {
        path: '/account',
        name: 'account',
        component: VpnAccount
    },
    {
        path: '/',
        name: 'Home',
        component: MainPage
    },
    {
        path: '/buy_activation_code',
        name: 'buy_activation_code',
        component: BuyActivationCode
    },
    {
        path: '/payeer_success',
        name: 'payeer_success',
        component: PaymentSuccess
    },
    {
        path: '/payeer_fail',
        name: 'payeer_fail',
        component: PaymentFail
    },
    {
        path: '/limit',
        name: 'limit',
        component: LimitPage
    },
    {
        path: '/recruitment',
        name: 'recruitment',
        component: ResellerRecruit
    },

    { path: '/:pathMatch(.*)*', redirect: '/' }, // Catch-all redirect to root path
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

// 全局路由守卫
router.beforeEach((to, from, next) => {
    const userLanguage = navigator.language || navigator.userLanguage;
    const isTest = to.query.test === 'true';
    // 判断是否是主页路径
    const isHomePage = to.path === '/';


    // 如果用户语言是简体中文，则重定向到错误页面
    if (userLanguage === 'zh-CN' && isHomePage && !isTest) {
        next('/limit');
    } else {
        next();
    }
});

export default router;